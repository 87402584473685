import { CommonRequest } from '../types';
import { RootState } from '../types/store-types';
import { getUrl } from '../../common/store/location/location-selectors';
import { resolveCommentDeepLink } from '../../api/comments/resolve-comment-deep-link.api';
import { URI } from '@wix/communities-forum-client-commons';
import { getForumSectionUrl } from '../../common/services/get-section-url';
import { IWixAPI } from '@wix/yoshi-flow-editor';

export const resolveWixCommentsDeepLink = (commentId: string, postId?: string) => {
  return (
    _dispatch: any,
    getState: any,
    { request, wixCodeApi }: { request: CommonRequest; wixCodeApi: IWixAPI },
  ) => {
    const state: RootState = getState();
    const url = getUrl(state);

    const pageUrl = wixCodeApi.location.baseUrl;
    const sectionUrl = getForumSectionUrl(wixCodeApi);
    const sectionPath = sectionUrl.replace(pageUrl, '');

    return request.httpClient
      .request(resolveCommentDeepLink({ commentId, postId }))
      .then(({ data }) => {
        // @ts-expect-error // second param for URI is not mandatory
        // Persist query params
        const query = new URI(url).query();
        const queryString = query ? `?${query}` : '';

        let relativePath = data;

        // Required, because resolveCommentDeepLink is not handling multilingual, when language is set as a slug e.x wixsite.com/some-site/lt/forum and not through query params
        // in this case resolveCommentDeepLink returns url with base as wixsite.com/some-site/ while navigateWithinForum sees it wixsite.com/some-site/lt/forum and doesn't combine properly
        if (relativePath.includes(sectionPath)) {
          const splitBySectionPath = relativePath.split(sectionPath);

          relativePath = splitBySectionPath.length > 1 ? splitBySectionPath[1] : relativePath;
        }

        return `${relativePath}${queryString}`;
      });
  };
};
